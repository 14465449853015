import React from 'react';
import {AuthContext} from '../context/auth';
import Header from './Header.js';
import {Navigate} from 'react-router-dom';

class Overview extends React.Component {
  render() {
    console.log(this.context.token);
    if (!this.context.token) {
      return <Navigate to="/tokenerror" />;
    }

    return (
      <div>
        <Header />
        <div className="center">
          <a className="verylargeButton" href="/#/tutorialoverview">
            Tutorial Overview
          </a>
          <a className="verylargeButton" href="/#/workshop/test">
            Workshop Agent
          </a>
          <a className="verylargeButton" href="http://18.185.53.73:3002/">
            Testpipeline
          </a>
        </div>
      </div>
    );
  }
}

Overview.contextType = AuthContext;
export default Overview;
