import React, {useState, useEffect, useContext, useRef, useCallback} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useParams} from 'react-router';
import axios from 'axios';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {backend} from '../config.js';
import {AuthContext} from '../context/auth';
import Header from './Header.js';

const NewSlide = () => {
  const {token} = useContext(AuthContext);
  const [images, setImages] = useState([]);
  const [active, setActive] = useState([]);
  const [loadCnt, setLoadCnt] = useState(0);
  const cancelToken = axios.CancelToken.source();

  let navigate = useNavigate();
  const urlParams = useParams();
  const start = useRef(Number(urlParams.prevTime) - 1000);
  const end = Number(urlParams.nextTime);
  const uuid = urlParams.uuid;
  const stepNo = Number(urlParams.stepNo);
  const prevStepNo = Number(urlParams.prevStepNo);

  const getImages = useCallback(() => {
    start.current += 1000;
    const request_end = Math.min(start.current + 1000, end);
    axios
      .get(`${backend}/getIntermediateImages?uuid=${uuid}&start=${start.current}&end=${request_end}`, {
        headers: {
          Authorization: token,
        },
        cancelToken: cancelToken.token,
      })
      .then(response => {
        const new_images = [...images, ...response.data];
        setImages(new_images);
        setLoadCnt(loadCnt + 1);
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          start.current = end;
          setImages([]);
          console.error(err);
          toast.error(`${err.response.status}: Error loading images`, {position: 'top-right'});
        }
      });
  }, [cancelToken, end, images, loadCnt, token, uuid]);

  const enlarge = idx => {
    let activeNew = [...active];
    activeNew.push(idx);
    setActive(activeNew);
  };

  const addImage = idx => {
    cancelToken.cancel('Operation canceled');
    axios
      .post(
        `${backend}/addImage`,
        {
          uuid: uuid,
          step: stepNo,
          file: images[idx].file,
          fileName: images[idx].fileName,
        },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then(() => {
        navigate(`/edittutorial/${uuid}/${stepNo}`);
      })
      .catch(err => {
        console.log(err);
        toast.error(`${err.response.status}: Error adding image`, {toastId: '1234'});
      });
  };

  useEffect(() => {
    if (end > start.current + 1000) {
      getImages();
    }
  }, [loadCnt, end, getImages]);

  let gallery = [];
  for (let idx in images) {
    let button = '';
    let className = 'small';
    if (active.includes(idx)) {
      button = <button onClick={() => addImage(idx)}>add this image</button>;
      className = 'big';
    }

    gallery.push(
      <div key={idx}>
        <img
          src={`data:image/png;base64,${images[idx].file}`}
          className={className}
          alt=""
          onClick={() => enlarge(idx)}
        />
        {button}
      </div>,
    );
  }

  return (
    <div>
      <ToastContainer />
      <Header />
      <Link to={`/edittutorial/${uuid}/${prevStepNo}`}>
        <button className="downloadButton">back</button>
      </Link>
      <h1>Select new image</h1>
      <div className="gallery">{gallery}</div>
    </div>
  );
};

export default NewSlide;
