import {useState} from 'react';
import ReactImageZoom from 'react-image-zoom';
import {blank, cursorWhite} from '../pngImages.js';

const ScreenshotViewer = props => {
  const [screenshot, setScreenshot] = useState('');
  let imgsrc;
  let imagesize;
  let mouseposition;

  if (props.image) {
    imgsrc = `data:image/png;base64,${props.image}`;
  } else {
    imgsrc = blank;
  }
  if (props.step?.imagesize) {
    imagesize = props.step.imagesize;
  } else {
    imagesize = {width: 800, height: 600};
  }
  if (props.step?.event?.mouseposition) {
    mouseposition = props.step.event.mouseposition;
  } else {
    mouseposition = undefined;
  }

  let cursor = new Image();
  cursor.src = cursorWhite;
  let img = new Image();
  img.src = imgsrc;
  img.onload = () => {
    const oc = document.createElement('canvas');
    const octx = oc.getContext('2d');
    oc.width = imagesize.width;
    oc.height = imagesize.height;
    octx.drawImage(img, 0, 0);
    if (mouseposition) {
      octx.drawImage(cursor, mouseposition.xPos, mouseposition.yPos);
    }
    setScreenshot(oc.toDataURL());
  };

  if (!screenshot) {
    return <></>;
  }

  return <ReactImageZoom img={screenshot} zoomScale={3} zoomPosition="original" width={800} />;
};

export default ScreenshotViewer;
