import React, {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import {backend} from '../config.js';
import {AuthContext} from '../context/auth';
import Header from './Header.js';

const WorkshopSelection = props => {
  const {token} = useContext(AuthContext);
  const [loaded, setLoaded] = useState(false);
  const [trainerWorkshops, setTrainerWorkshops] = useState([]);

  useEffect(() => {
    if (token) {
      axios
        .get(`${backend}/getUserinfo`, {
          headers: {
            Authorization: token,
          },
        })
        .then(response => {
          console.log(response.data);
          if (response.data?.trainerWorkshops) {
            setTrainerWorkshops(response.data.trainerWorkshops);
          }
          setLoaded(true);
        })
        .catch(err => {
          console.log(err);
          setLoaded(true);
        });
    }
  }, [token]);

  const createContent = () => {
    if (!loaded) {
      return 'loading....';
    } else if (trainerWorkshops.length) {
      return trainerWorkshops.map(e => (
        <a className="verylargeButton" href={`/#/workshop/${e}`}>
          {e}
        </a>
      ));
    } else {
      return 'you are not trainer of any workshop';
    }
  };

  return (
    <div>
      <Header />
      <button
        className="downloadButton"
        onClick={() => {
          props.history.push('/');
        }}>
        back
      </button>
      <div className="center">{createContent()}</div>
    </div>
  );
};

export default WorkshopSelection;
