import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useParams} from 'react-router';
import axios from 'axios';
import {Scrollbars} from 'react-custom-scrollbars-2';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {backend, websocketBackend} from '../config.js';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {blank} from '../pngImages.js';

const all_algorithms = ['contour', 'template', 'feature', 'autopy', 'FFHS_qatm', 'FFHS_cc', 'templatefeature']; //ToDo: get algorithms from backend

const SecondScreen = () => {
  const canvasRef = useRef();
  const ws = useRef();
  const stepUpdater = useRef();
  const disabledSteps = useRef([]);
  const [allSlides, setAllSlides] = useState([]);
  const [images, setImages] = useState({});
  const [title, setTitle] = useState('');
  const [stepNo, setStepNo] = useState(0);
  const [, forceUpdate] = useState();
  const [autosync, setAutosync] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [algorithm, setAlgorithm] = useState(all_algorithms[0]);
  const {tutorialId, sessionId} = useParams();

  const startWebsocket = useCallback(
    nextMessage => {
      ws.current = new WebSocket(websocketBackend);
      ws.current.onopen = () => {
        const message = `{"eventtype": "start", "algorithm": "${algorithm}", "tutorialid": "${tutorialId}", "sessionid": "${sessionId}", "service": "secondscreen"}`;
        if (ws.current.readyState === 1) {
          //should always be true
          ws.current.send(message);
          setAutosync(true);
          if (nextMessage) {
            ws.current.send(nextMessage);
          }
        }
      };

      ws.current.onmessage = event => {
        const data = JSON.parse(event.data);
        if ('type' in data && data.type === 'stepchange') {
          setStepNo(data.stepnumber);
          stepUpdater.current = 'backend';
        } else if ('type' in data && data.type === 'mistake') {
          let message;
          if (data.code === 1) {
            message = `You clicked ${data.got} but we expected ${data.expected}. If we are incorrect, please click manually to the next step.`;
          } else if (data.code === 2) {
            message = `We identfied a click at the wrong position. If we are incorrect, please click manually to the next step.`;
          } else {
            message = 'Wrong click.';
          }
          toast.info(message, {
            toastId: '1234',
          });
        }
      };

      ws.current.onclose = () => {
        //console.log('ws connection closed');
      };
    },
    [algorithm, tutorialId, sessionId],
  );

  const stopWebsocket = useCallback(() => {
    if (ws.current && ws.current.readyState === 1) {
      ws.current.send(`{"type": "deregistration", "sessionid": "${sessionId}", "service": "secondscreen"}`);
      ws.current.close();
    }
    setAutosync(false);
  }, [sessionId]);

  const triggerAutosync = () => {
    if (autosync) {
      stopWebsocket();
    } else {
      startWebsocket(undefined);
    }
  };

  const goBack = useCallback(() => {
    setStepNo(stepNoPrev => {
      let stepNoNew = stepNoPrev;
      while (stepNoNew > 0) {
        stepNoNew -= 1;
        if (!disabledSteps.current.includes(stepNoNew)) {
          return stepNoNew;
        }
      }
      return stepNoPrev;
    });
    stepUpdater.current = 'manual';
  }, []);

  const goForth = useCallback(() => {
    setStepNo(stepNoPrev => {
      let stepNoNew = stepNoPrev;
      while (stepNoNew < allSlides.length - 1) {
        stepNoNew += 1;
        if (!disabledSteps.current.includes(stepNoNew)) {
          return stepNoNew;
        }
      }
      return stepNoPrev;
    });
    stepUpdater.current = 'manual';
  }, [allSlides]);

  const handleKeyEvent = useCallback(
    e => {
      if (e.keyCode === 39) {
        goForth();
      } else if (e.keyCode === 37) {
        goBack();
      }
    },
    [goForth, goBack],
  );

  const loadImage = useCallback(
    stepNo => {
      if (allSlides.length > 0 && !images[stepNo] && allSlides[stepNo]?.imagename) {
        axios
          .get(`${backend}/getImage?uuid=${tutorialId}&type=image&imagename=${allSlides[stepNo].imagename}`)
          .then(response => {
            setImages(prevImages => {
              let newImages = {...prevImages};
              newImages[stepNo] = response.data;
              //delete images more than 50 slides away to save memory
              newImages = Object.fromEntries(Object.entries(newImages).filter(([key]) => Math.abs(key - stepNo) < 50));
              return newImages;
            });
          })
          .catch(() => {
            toast.error('no images found', {position: 'top-right'});
          });
      }
    },
    [allSlides, images, tutorialId],
  );

  const drawScreenshot = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      let img = new Image();
      if (allSlides) {
        const step = allSlides[stepNo];
        if (images[stepNo]) {
          img.src = `data:image/png;base64,${images[stepNo]}`;
          img.onload = () => {
            // prefilter (blur)
            const oc = document.createElement('canvas');
            const octx = oc.getContext('2d');
            oc.width = step.imagesize.width;
            oc.height = step.imagesize.height;
            const fsteps = (oc.width / canvas.width) >> 1;
            octx.filter = `blur(${fsteps}px)`;
            octx.drawImage(img, 0, 0);

            //draw blurred image
            ctx.drawImage(oc, 0, 0, oc.width, oc.height, 0, 0, canvas.width, canvas.height);
          };
        } else {
          img.src = blank;
          img.onload = () => {
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          };
        }
      }
    }
  };

  const getTitle = () => {
    for (let idx = stepNo; idx >= 0; idx--) {
      const title = allSlides[idx].instructions.title;
      if (title && title !== '') {
        return title;
      }
    }
    return '';
  };

  const createMarkup = () => {
    const step = allSlides[stepNo];
    return {__html: step.instructions.description};
  };

  const getEventtype = () => {
    const eventtype = allSlides[stepNo]?.event?.type;
    if (!eventtype) {
      return undefined;
    }
    if (eventtype === 'left') {
      return (
        <>
          <div>1x</div>
          <Tooltip title="left mouse click" placement="right">
            <img src={`${process.env.PUBLIC_URL}/left-click-w.png`} width="30" alt="left mouse click" />
          </Tooltip>
        </>
      );
    } else if (eventtype === 'right') {
      return (
        <>
          <div>1x</div>
          <Tooltip title="right mouse click" placement="right">
            <img src={`${process.env.PUBLIC_URL}/right-click-w.png`} width="30" alt="right mouse click" />
          </Tooltip>
        </>
      );
    } else if (eventtype === 'double') {
      return (
        <>
          <div>2x</div>
          <Tooltip title="double click" placement="right">
            <img src={`${process.env.PUBLIC_URL}/left-click-w.png`} width="30" alt="left mouse click" />
          </Tooltip>
        </>
      );
    } else if (eventtype === 'drag and drop (end)') {
      return (
        <>
          <Tooltip title="drag and drop" placement="right">
            <img src={`${process.env.PUBLIC_URL}/drag-w.png`} width="30" alt="drag and drop" />
          </Tooltip>
        </>
      );
    } else if (allSlides[stepNo]?.event?.word) {
      return (
        <>
          <Tooltip title="type on keyboard" placement="right">
            <img src={`${process.env.PUBLIC_URL}/keyboard-w.png`} width="30" alt="type on keyboard" />
          </Tooltip>
          {`type: ${allSlides[stepNo].event.word}`}
        </>
      );
    } else if (eventtype === 'sync') {
      return '';
    }
    return '';
  };

  useEffect(() => {
    //load tutorial data
    axios
      .get(`${backend}/getTutorial?uuid=${tutorialId}`)
      .then(response => {
        setAllSlides(response.data[0].steps);
        setTitle(response.data[0].title);
        disabledSteps.current = [];
        for (let idx = 0; idx < response.data[0].steps.length; idx += 1) {
          const step = response.data[0].steps[idx];
          if (!step.instructions.display || !step.imagename) {
            disabledSteps.current.push(idx);
          }
        }
      })
      .catch(err => {
        console.error(err);
      });
  }, [tutorialId]);

  useEffect(() => {
    startWebsocket(undefined);
    window.addEventListener('resize', () => forceUpdate(window.innerWidth));
    window.addEventListener('keydown', handleKeyEvent);
    return () => {
      stopWebsocket();
      window.removeEventListener('resize', () => forceUpdate(window.innerWidth));
      window.removeEventListener('keydown', handleKeyEvent);
    };
  }, [startWebsocket, stopWebsocket, handleKeyEvent]);

  useEffect(() => {
    if (allSlides[stepNo] && !allSlides[stepNo].instructions.display) {
      goForth();
    } else {
      //load images if not cached already
      loadImage(stepNo);
      loadImage(stepNo + 1);
      //send update to backend
      if (ws.current && autosync && stepUpdater.current === 'manual') {
        stepUpdater.current = '';
        const message = `{"type": "stepchange", "stepnumber": ${stepNo}, "algorithm": "${algorithm}", "tutorialid": "${tutorialId}", "sessionid": "${sessionId}", "service": "secondscreen"}`;
        if (ws.current.readyState !== 1) {
          startWebsocket(message);
        } else {
          // readyState=1 -> ready
          ws.current.send(message);
        }
      }
    }
  }, [stepNo, images, allSlides, tutorialId, autosync, algorithm, sessionId, goForth, startWebsocket, loadImage]);

  const step = allSlides[stepNo];
  if (!step) {
    return (
      <div className="secondscreen">
        <div className="colResizeHandle"></div>
      </div>
    );
  }

  // define image (canvas) size
  const imageSize = step.imagesize;
  let imageRatio;
  if (imageSize.height > 0) {
    imageRatio = imageSize.width / imageSize.height;
  } else {
    imageRatio = 1;
  }
  const windowRatio = window.innerWidth / window.innerHeight;
  let canvasWidth;
  let canvasHeight;
  if (imageRatio > windowRatio) {
    canvasWidth = window.innerWidth;
    canvasHeight = parseInt(canvasWidth / imageRatio);
  } else {
    canvasHeight = window.innerHeight;
    canvasWidth = parseInt(imageRatio * canvasHeight);
  }

  //define speech bubbles position
  let xPosMouse = undefined;
  let yPosMouse = undefined;
  let boardPosition = {};
  let boardClass = '';
  let bottomOffset = window.innerHeight - canvasHeight;
  if (allSlides) {
    const step = allSlides[stepNo];
    if (step.event.mouseposition) {
      xPosMouse = (canvasWidth / step.imagesize.width) * step.event.mouseposition.xPos;
      yPosMouse = (canvasHeight / step.imagesize.height) * step.event.mouseposition.yPos;
      if (xPosMouse * 1 + 300 < canvasWidth) {
        if (yPosMouse * 1 + 300 < canvasHeight) {
          boardClass = 'sb_q4';
          boardPosition.left = xPosMouse + 19;
          boardPosition.top = yPosMouse - 6;
        } else {
          boardClass = 'sb_q1';
          boardPosition.left = xPosMouse - 19;
          boardPosition.bottom = canvasHeight - yPosMouse + 19 + bottomOffset;
        }
      } else {
        if (yPosMouse * 1 + 300 < canvasHeight) {
          boardClass = 'sb_q3';
          boardPosition.left = xPosMouse - 319;
          boardPosition.top = yPosMouse - 6;
        } else {
          boardClass = 'sb_q2';
          boardPosition.left = xPosMouse - 281;
          boardPosition.bottom = canvasHeight - yPosMouse + 19 + bottomOffset;
        }
      }
    } else {
      boardPosition.left = canvasWidth - 300;
      boardPosition.bottom = bottomOffset;
    }
  }

  drawScreenshot();

  const stepNo_display = stepNo + 1 - disabledSteps.current.filter(e => e < stepNo).length;
  const totalSteps_display = allSlides.length - disabledSteps.current.length;
  const board = (
    <div className={`sb ${boardClass}`} style={boardPosition}>
      <div className="eventtype">{getEventtype()}</div>
      <IconButton id="kebebbutton" onClick={e => setAnchorEl(e.currentTarget)}>
        <MoreVertIcon htmlColor="white" />
      </IconButton>
      <Menu
        id="contextMenu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: -10,
          horizontal: 265,
        }}>
        <MenuItem disableRipple={true}>
          auto sync <Switch onChange={triggerAutosync} checked={autosync} />
        </MenuItem>
        <MenuItem disableRipple={true}>
          {' '}
          Algorithm
          <select onChange={e => setAlgorithm(e.target.value)} value={algorithm}>
            {all_algorithms.map(e => (
              <option key={e}>{e}</option>
            ))}
          </select>
        </MenuItem>
      </Menu>
      <Scrollbars autoHeight autoHeightMax={200}>
        <div className="text">
          <h2>{getTitle()}</h2>
          <div className="description" dangerouslySetInnerHTML={createMarkup()} />
        </div>
      </Scrollbars>
      <div className="navigation">
        <div onClick={goBack} className={stepNo_display === 1 ? 'disabled' : 'active'}>
          <img src="backward.png" alt="zurück" height={32} />
        </div>
        <div>
          {stepNo_display} of {totalSteps_display} steps
          <br />
        </div>
        <div onClick={goForth} className={stepNo_display === totalSteps_display ? 'disabled' : 'active'}>
          <img src="forward.png" alt="vorwärts" height={32} />
        </div>
      </div>
      <div className="smalltitle">{title}</div>
    </div>
  );

  return (
    <div className="secondscreen">
      <ToastContainer position="top-center" />
      <div className="mainscreen">
        <div onClick={goBack} className="leftHoverColumn">
          <img src="backward.png" alt="zurück" height={32} />
        </div>
        <canvas ref={canvasRef} width={canvasWidth} height={canvasHeight}></canvas>
        <div onClick={goForth} className="rightHoverColumn">
          <img src="forward.png" alt="vorwärts" height={32} />
        </div>
      </div>
      {board}
    </div>
  );
};

export default SecondScreen;
