import React from 'react';
import {EditorState, convertToRaw, convertFromHTML, ContentState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class RichEditor extends React.Component {
  constructor() {
    super();
    this.state = {
      editorState: EditorState.createEmpty(),
    };
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
    this.updateProps = this.updateProps.bind(this);
  }

  componentDidMount() {
    this.updateProps();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.stepNo !== this.props.stepNo) {
      this.updateProps();
    }
  }

  updateProps() {
    if (this.props.text) {
      const contentBlock = convertFromHTML(this.props.text);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks, contentBlock.entityMap);
        const editorState = EditorState.createWithContent(contentState);
        this.setState({
          editorState,
        });
      }
    }
  }

  onEditorStateChange(editorState) {
    this.setState(
      {
        editorState,
      },
      () => {
        if (this.props.onChange) {
          const description = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
          this.props.onChange(description);
        }
      },
    );
  }

  render() {
    const toolbar = {
      options: ['inline', 'list'],
      inline: {inDropdown: false, options: ['bold', 'italic', 'underline']},
      list: {inDropdown: false, options: ['unordered', 'ordered']},
    };

    return (
      <Editor
        editorClassName="editorClass"
        editorState={this.state.editorState}
        onEditorStateChange={this.onEditorStateChange}
        toolbar={toolbar}
        stripPastedStyles={true}
        onFocus={this.props.focus}
        onBlur={this.props.blur}
      />
    );
  }
}
export default RichEditor;
