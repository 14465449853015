const formatTime = time => {
  const dateTimeFormat = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };
  if (time) {
    return new Intl.DateTimeFormat('de-CH', dateTimeFormat).format(time);
  } else {
    return '';
  }
};

export {formatTime};
