import React, {useState, useEffect, useContext, useRef, useCallback} from 'react';
import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import {Link} from 'react-router-dom';
import Switch from '@mui/material/Switch';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RichEditor from './RichEditor.js';
import {backend} from '../config.js';
import {AuthContext} from '../context/auth';
import Header from './Header.js';
import ScreenshotViewer from './ScreenshotViewer.js';

const SlideEditor = () => {
  const {token} = useContext(AuthContext);
  const disabledSteps = useRef([]);
  const typingTimeout = useRef();
  const totalSlides = useRef(0);
  const activeDelay = useRef(false);
  const pendingChanges = useRef(false);
  const [stepNo, setStepNo] = useState(0);
  const [allSlides, setAllSlides] = useState([]);
  const [images, setImages] = useState({});
  const [activeTextfield, setActiveTextfield] = useState(false);

  const {uuid, initialStepNo} = useParams();
  let navigate = useNavigate();

  const saveToDB = useCallback(() => {
    if (pendingChanges.current && totalSlides.current > 0 && allSlides[stepNo]) {
      axios
        .post(
          `${backend}/editTutorial`,
          {
            uuid: uuid,
            step: stepNo,
            title: allSlides[stepNo].instructions.title,
            description: allSlides[stepNo].instructions.description,
            display: allSlides[stepNo].instructions.display,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then(() => {})
        .catch(err => {
          toast.error('error saving text', {toastId: '1234'});
        });
    }
  }, [allSlides, stepNo, token, uuid]);

  const setText = event => {
    const text = event.target.value;
    const name = event.target.name;
    let newImageStream = [...allSlides];
    newImageStream[stepNo].instructions[name] = text;
    setAllSlides(newImageStream);
    pendingChanges.current = true;
  };

  const setDescFromRichEditor = description => {
    let newImageStream = [...allSlides];
    newImageStream[stepNo].instructions.description = description;
    setAllSlides(newImageStream);
    pendingChanges.current = true;
  };

  const hideSlide = useCallback(() => {
    let newImageStream = [...allSlides];
    newImageStream[stepNo].instructions.display = !newImageStream[stepNo].instructions.display;
    setAllSlides(newImageStream);
    pendingChanges.current = true;
  }, [allSlides, stepNo]);

  const toOverview = () => {
    saveToDB();
    navigate(`/tutorial/${uuid}`);
  };

  const changeStep = useCallback(
    newStepNo => {
      if (!activeDelay.current) {
        activeDelay.current = true;
        saveToDB();
        pendingChanges.current = false;
        setTimeout(() => {
          setStepNo(newStepNo);
          activeDelay.current = false;
        }, 500); //delay to prevent server overload
      }
    },
    [saveToDB],
  );

  const goBack = useCallback(() => {
    let stepNoTmp = stepNo;
    let stepNoNew = undefined;
    while (stepNoTmp > 0) {
      stepNoTmp -= 1;
      if (!disabledSteps.current.includes(stepNoTmp)) {
        stepNoNew = stepNoTmp;
        break;
      }
    }
    if (!stepNoNew) {
      stepNoNew = stepNo;
    }
    changeStep(stepNoNew);
  }, [stepNo, changeStep]);

  const goForth = useCallback(() => {
    let stepNoTmp = stepNo;
    let stepNoNew = undefined;
    while (stepNoTmp < totalSlides.current - 1) {
      stepNoTmp += 1;
      if (!disabledSteps.current.includes(stepNoTmp)) {
        stepNoNew = stepNoTmp;
        break;
      }
    }
    if (!stepNoNew) {
      stepNoNew = stepNo;
    }
    changeStep(stepNoNew);
  }, [stepNo, changeStep]);

  const getPlaceholderTitle = () => {
    for (let idx = stepNo; idx >= 0; idx--) {
      const title = allSlides[idx].instructions.title;
      if (title && title !== '') {
        return title;
      }
    }
    return 'Slide Title';
  };

  const loadImage = useCallback(
    stepNo => {
      if (allSlides[stepNo]?.imagename && !images[stepNo]) {
        axios
          .get(`${backend}/getImage?uuid=${uuid}&type=image&imagename=${allSlides[stepNo].imagename}`, {
            headers: {
              Authorization: token,
            },
          })
          .then(response => {
            setImages(prevImages => {
              let newImages = {...prevImages};
              newImages[stepNo] = response.data;
              //delete images more than 50 slides away to save memory
              newImages = Object.fromEntries(Object.entries(newImages).filter(([key]) => Math.abs(key - stepNo) < 50));
              return newImages;
            });
          })
          .catch(() => {
            toast.error('no images found', {position: 'top-right'});
          });
      }
    },
    [allSlides, images, token, uuid],
  );

  const handleKeyEvent = useCallback(
    e => {
      if (!activeTextfield) {
        if (e.keyCode === 39) {
          goForth();
        } else if (e.keyCode === 37) {
          goBack();
        } else if (e.keyCode === 68) {
          hideSlide();
        }
      }
    },
    [goForth, goBack, hideSlide, activeTextfield],
  );

  useEffect(() => {
    axios
      .get(`${backend}/getTutorials?uuid=${uuid}&includesync=true`, {
        headers: {
          Authorization: token,
        },
      })
      .then(response => {
        if (!('data' in response) || response.data[0].steps.length === 0) {
          toast.error('no slides found', {position: 'top-right'});
        } else {
          let draganddropstarts = [];
          for (let idx = 0; idx < response.data[0].steps.length; idx++) {
            if (response.data[0].steps[idx].event.type === 'drag and drop (start)') {
              draganddropstarts.push(idx);
            }
          }
          let correctedStepNo = initialStepNo || 0;
          while (draganddropstarts.includes(correctedStepNo)) {
            correctedStepNo += 1;
          }
          disabledSteps.current = draganddropstarts;
          totalSlides.current = response.data[0].steps.length;
          setAllSlides(response.data[0].steps);
          setStepNo(correctedStepNo);
        }
      })
      .catch(() => {
        toast.error('could not load tutorial data', {position: 'top-right'});
      });
  }, [uuid, token, initialStepNo]);

  useEffect(() => {
    //only save to DB if there was no typing for 2s
    if (typingTimeout.current) {
      clearTimeout(typingTimeout.current);
    }
    typingTimeout.current = setTimeout(() => {
      saveToDB();
    }, 2000);
  }, [allSlides, saveToDB]);

  useEffect(() => {
    if (totalSlides.current > 0) {
      loadImage(stepNo);
      loadImage(stepNo + 1);
    }
  }, [stepNo, loadImage]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyEvent);
    return () => {
      window.removeEventListener('keydown', handleKeyEvent);
    };
  }, [handleKeyEvent]);

  let editorView;
  let leftArrow;
  let rightArrow;
  if (totalSlides.current > 0 && stepNo < totalSlides.current && !disabledSteps.current.includes(stepNo)) {
    let step = allSlides[stepNo];
    let placeholderTitle = getPlaceholderTitle();
    let title = step.instructions.title || '';
    let description = step.instructions.description || '<div></div>';
    let prevTime;
    let curTime;
    let nextTime;
    if (allSlides[stepNo - 1]) {
      prevTime = allSlides[stepNo - 1].timestamp;
    } else {
      prevTime = 0;
    }
    curTime = allSlides[stepNo].timestamp;
    if (allSlides[stepNo + 1]) {
      nextTime = allSlides[stepNo + 1].timestamp;
    } else {
      nextTime = Math.pow(2, 50);
    }

    let eventType = step.event.type || '';
    if (step.event.word) {
      eventType = (
        <>
          type "{step.event.word}"<br />
          {eventType}
        </>
      );
    }

    editorView = (
      <div>
        <input
          type="text"
          name="title"
          value={title}
          onChange={setText}
          onFocus={() => setActiveTextfield(true)}
          onBlur={() => setActiveTextfield(false)}
          placeholder={placeholderTitle}
          className="editorInputTitle"></input>
        <br />
        <div className={step.instructions.display ? 'editorImage' : 'editorImage disabled'}>
          <ScreenshotViewer image={images[stepNo]} step={allSlides[stepNo]} />
        </div>
        {eventType}
        <RichEditor
          text={description}
          stepNo={stepNo}
          onChange={setDescFromRichEditor}
          focus={() => setActiveTextfield(true)}
          blur={() => setActiveTextfield(false)}
        />
        <div className="options">
          <Switch onChange={hideSlide} checked={step.instructions.display} />
          <span className="sliderText">display slide [d]</span>
          <Link to={`/newslide/${uuid}/${stepNo}/${stepNo}/${prevTime}/${curTime}`}>
            <div className="smallbutton">new slide before</div>
          </Link>
          <Link to={`/newslide/${uuid}/${stepNo}/${stepNo + 1}/${curTime}/${nextTime}`}>
            <div className="smallbutton">new slide after</div>
          </Link>
        </div>
      </div>
    );

    leftArrow = (
      <button className="arrow" onClick={goBack}>
        &#8249;
      </button>
    );
    rightArrow = (
      <button className="arrow" onClick={goForth}>
        &#8250;
      </button>
    );
    if (stepNo - disabledSteps.current.filter(e => e < stepNo).length === 0) {
      leftArrow = <button className="arrow arrowInactive">&#8249;</button>;
    }
    if (
      stepNo - disabledSteps.current.filter(e => e < stepNo).length ===
      totalSlides.current - disabledSteps.current.length - 1
    ) {
      rightArrow = <button className="arrow arrowInactive">&#8250;</button>;
    }
  } else {
    editorView = '';
    leftArrow = '';
    rightArrow = '';
  }

  let slideNumberView = '';
  if (totalSlides.current > 0) {
    slideNumberView = `Slide ${stepNo + 1 - disabledSteps.current.filter(e => e < stepNo).length}/${
      totalSlides.current - disabledSteps.current.length
    }`;
  }

  return (
    <div>
      <ToastContainer pauseOnFocusLoss={false} />
      <Header />
      <button className="downloadButton" onClick={toOverview}>
        back
      </button>
      <span className="titlelike">Tutorial Editor: {slideNumberView}</span>
      <div className="editor">
        {leftArrow}
        <div className="editorView">{editorView}</div>
        {rightArrow}
      </div>
    </div>
  );
};

export default SlideEditor;
