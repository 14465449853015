import React from 'react';
import axios from 'axios';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {backend} from '../config.js';
import {AuthContext} from '../context/auth';

class ImageSelector extends React.Component {
  state = {
    images: [],
    active: [],
  };

  componentDidMount() {
    axios
      .get(`${backend}/getTutorials?uuid=${this.props.tutorialId}&props=images&includesync=true`, {
        headers: {
          Authorization: this.context.token,
        },
      })
      .then(response => {
        if (!('data' in response) || response.data.length === 0) {
          toast.error('Error', {position: 'top-right'});
        } else if (response.data[0].steps.length === 0) {
          toast.error('No images found', {position: 'top-right'});
        } else {
          this.setState({
            images: response.data[0].steps.map(item => item.image),
          });
        }
      })
      .catch(err => {
        this.setState({
          images: [],
        });
        console.log(err);
        toast.error('Error', {position: 'top-right'});
      });
  }

  enlarge = idx => {
    let active = [...this.state.active];
    active.push(idx);
    this.setState({
      active: active,
    });
  };

  render() {
    let gallery = [];
    for (let idx in this.state.images) {
      let button = '';
      let className = 'small';
      if (this.state.active.includes(idx)) {
        button = <button onClick={() => this.props.update(idx)}>select image</button>;
        className = 'big';
      }

      gallery.push(
        <div key={idx}>
          <img
            src={`data:image/png;base64,${this.state.images[idx]}`}
            className={className}
            alt=""
            onClick={() => this.enlarge(idx)}
          />
          {button}
        </div>,
      );
    }

    return (
      <div>
        <ToastContainer />
        <button className="downloadButton" onClick={() => this.props.update(undefined)}>
          back
        </button>
        <h2>Select title image</h2>
        <div className="gallery">{gallery}</div>
      </div>
    );
  }
}

ImageSelector.contextType = AuthContext;
export default ImageSelector;
