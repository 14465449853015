import React from 'react';

const Tokenerror = () => {
  return (
    <div className="warning">
      Authentication unsuccessful. Please log in on{' '}
      <a href="http://agent.mylivezone.com">http://agent.mylivezone.com</a>, start a workplace and click on "Downloads
      JWT".
    </div>
  );
};

export default Tokenerror;
