import React, {useState, useEffect, useContext, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {useParams} from 'react-router';
import axios from 'axios';
import {Link} from 'react-router-dom';
import FileDownload from 'js-file-download';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './Header';
import ImageSelector from './ImageSelector';
import {AuthContext} from '../context/auth';
import {blank} from '../pngImages';
import {formatTime} from '../util';
import {backend} from '../config';

const TutorialView = () => {
  const {token} = useContext(AuthContext);
  const [tutorial, setTutorial] = useState();
  const [editOngoing, setEditOngoing] = useState({title: false, subtitle: false, titleimage: false});
  const [temp, setTemp] = useState({title: '', subtitle: ''});
  const [fileSizes, setFileSizes] = useState();
  const [titleImage, setTitleImage] = useState(blank);
  const [time, setTime] = useState();
  const [loading, setLoading] = useState({
    zipwithoutcursor: false,
    zipwithcursor: false,
    pptxcustom: false,
    pptxstandard: false,
    pdfcustom: false,
    video: false,
  });

  let navigate = useNavigate();
  const {tutorialId} = useParams();

  const getTitleImage = useCallback(
    tutorial => {
      const mainStep = tutorial.steps[tutorial.titleimage || 0];
      const imageName = mainStep ? mainStep.imagename : undefined;
      if (imageName) {
        axios
          .get(`${backend}/getImage?uuid=${tutorialId}&type=image&imagename=${imageName}`, {
            headers: {
              Authorization: token,
            },
          })
          .then(response => {
            setTitleImage(`data:image/png;base64,${response.data}`);
          })
          .catch(err => {
            console.error(err);
          });
      }
    },
    [token, tutorialId],
  );

  const loadTutorial = useCallback(() => {
    if (!token) {
      return 0;
    }
    axios
      .get(`${backend}/getTutorials?uuid=${tutorialId}&includesync`, {
        headers: {
          Authorization: token,
        },
      })
      .then(response => {
        if (!response.error) {
          const tutorial = response.data[0];
          getTitleImage(tutorial);
          setTutorial(tutorial);
          setTemp({title: tutorial.title, subtitle: tutorial.subtitle});
        } else {
          console.log(response);
        }
      })
      .catch(err => {
        console.error(err);
      });

    axios
      .get(`${backend}/getS3size?prefix=${tutorialId}/`, {
        headers: {
          Authorization: token,
        },
      })
      .then(response => {
        if (!response.error) {
          let fileSize = response.data.size;
          let unit = 'B';
          if (fileSize > 1024) {
            fileSize /= 1024;
            unit = 'KB';
          }
          if (fileSize > 1024) {
            fileSize /= 1024;
            unit = 'MB';
          }
          setFileSizes(`${parseInt(fileSize)} ${unit}`);
        }
      })
      .catch(err => {
        console.error(err);
      });
  }, [token, tutorialId, getTitleImage]);

  const restoreTutorial = () => {
    axios
      .post(
        `${backend}/deleteTutorial`,
        {
          uuid: tutorialId,
          restore: true,
        },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then(() => loadTutorial())
      .catch(err => console.error(err));
  };

  const triggerDownload = useCallback(
    (type, state = true) => {
      let loadingNew = {...loading};
      loadingNew[type] = state;
      setLoading(loadingNew);
    },
    [loading],
  );

  const edit = event => {
    let editOngoingNew = JSON.parse(JSON.stringify(editOngoing));
    editOngoingNew[event.target.name] = true;
    setEditOngoing(editOngoingNew);
  };

  const typping = event => {
    let tempNew = JSON.parse(JSON.stringify(temp));
    tempNew[event.target.name] = event.target.value;
    setTemp(tempNew);
  };

  const changeTitleImage = newTitleImage => {
    if (newTitleImage) {
      saveToDb('titleimage', newTitleImage);
    } else {
      let editOngoingNew = JSON.parse(JSON.stringify(editOngoing));
      editOngoingNew.titleimage = false;
      setEditOngoing(editOngoingNew);
    }
  };

  const save = event => {
    saveToDb(event.target.name, temp[event.target.name]);
  };

  const saveToDb = (type, value) => {
    let params = {
      uuid: tutorialId,
      step: -1,
      title: tutorial.title,
      subtitle: tutorial.subtitle,
      titleimage: tutorial.titleimage,
    };
    params[type] = value;
    let editOngoingNew = JSON.parse(JSON.stringify(editOngoing));
    let tutorialNew = JSON.parse(JSON.stringify(tutorial));

    editOngoingNew[type] = false;
    tutorialNew[type] = value;
    if (type === 'titleimage') {
      getTitleImage(tutorial);
    }
    axios
      .post(`${backend}/editTutorial`, params, {
        headers: {
          Authorization: token,
        },
      })
      .then(() => {
        setEditOngoing(editOngoingNew);
        setTutorial(tutorialNew);
      })
      .catch(err => {
        toast.error('error saving text', {toastId: '1234'});
      });
  };

  useEffect(() => {
    loadTutorial();
    const interval = setInterval(() => setTime(Date.now()), 10000); // to check download state

    return () => {
      clearInterval(interval);
    };
  }, [loadTutorial]);

  useEffect(() => {
    for (let type of Object.keys(loading)) {
      if (!loading[type]) {
        continue;
      }
      let downloadUrl = `${backend}/download/${type}?uuid=${tutorialId}`;
      console.log(downloadUrl);
      axios
        .get(downloadUrl, {
          headers: {
            Authorization: token,
          },
          responseType: 'blob',
        })
        .then(response => {
          if ('data' in response) {
            if ('content-disposition' in response.headers) {
              const reg = /filename="(.*)"/.exec(response.headers['content-disposition']);
              const fileName = reg[1];
              FileDownload(response.data, fileName);
              triggerDownload(type, false);
            } else {
              console.log(response.data);
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [time, token, tutorialId, loading, triggerDownload]);

  if (!tutorial) {
    return <></>;
  }

  let titleField;
  if (editOngoing.title) {
    titleField = (
      <>
        <input type="text" name="title" value={temp.title} onChange={typping} />
        <img src="save.png" alt="save" width="16" className="editIcon" name="title" onClick={save} />
      </>
    );
  } else {
    titleField = (
      <>
        {tutorial.title}
        <img
          src="pencil.png"
          alt="edit"
          width="16"
          className={`${tutorial.deleted || tutorial.editRights < 2 ? 'hide' : 'editIcon'}`}
          name="title"
          onClick={edit}
        />
      </>
    );
  }

  let subtitleField;
  if (editOngoing.subtitle) {
    subtitleField = (
      <>
        <textarea name="subtitle" value={temp.subtitle} onChange={typping} />
        <img src="save.png" alt="save" width="16" className="editIcon" name="subtitle" onClick={save} />
      </>
    );
  } else {
    subtitleField = (
      <>
        {tutorial.subtitle}
        <img
          src="pencil.png"
          alt="edit"
          width="16"
          className={`${tutorial.deleted || tutorial.editRights < 2 ? 'hide' : 'editIcon'}`}
          name="subtitle"
          onClick={edit}
        />
      </>
    );
  }

  let editBox;
  if (tutorial.editRights < 2) {
    editBox = 'no edit rights';
  } else if (tutorial.deleted) {
    editBox = (
      <table className="linkTable">
        <tbody>
          <tr>
            <td>
              <button className="linkButton" onClick={restoreTutorial}>
                Restore tutorial
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    );
  } else {
    editBox = (
      <table className="linkTable">
        <tbody>
          <tr>
            <td>
              <Link to={`/edittutorial/${tutorialId}`}>
                <button className="linkButton"> Edit slides</button>
              </Link>
            </td>
          </tr>
          {/*<tr>
            <td>
              <Link to={`/editroi/${tutorialId}`}>
                <button className="linkButton">Edit ROI</button>
              </Link>
            </td>
          </tr>*/}
          <tr>
            <td>
              <Link to={`/deletetutorial/${tutorialId}`}>
                <button className="linkButton">Delete tutorial</button>
              </Link>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  if (editOngoing.titleimage) {
    return (
      <div className="bodylike">
        <Header />
        <ImageSelector tutorialId={tutorialId} update={changeTitleImage} />
      </div>
    );
  }

  return (
    <div className="bodylike">
      <ToastContainer />
      <Header />
      <button
        className="downloadButton"
        onClick={() => {
          navigate('/tutorialoverview');
        }}>
        back
      </button>
      <div className="tutorial-view">
        <div className="column-half">
          <div className="box">
            <div className="imageFrame">
              <img src={titleImage} alt="titleimage" width="100%" className="titleImage" />
              <img
                src="pencil.png"
                alt="edit"
                width="16"
                className={`${tutorial.deleted ? 'hide' : 'editIcon'}`}
                name="titleimage"
                onClick={edit}
              />
            </div>
            <table className="textTable">
              <tbody>
                <tr>
                  <td>Title:</td>
                  <td>{titleField}</td>
                </tr>
                <tr>
                  <td>User:</td>
                  <td>{tutorial.username}</td>
                </tr>
                <tr>
                  <td>Organization:</td>
                  <td>{tutorial.organization}</td>
                </tr>
                <tr>
                  <td>Workplace:</td>
                  <td>{tutorial.workplace}</td>
                </tr>
                <tr>
                  <td>Type:</td>
                  <td>{tutorial.type}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="box">
            <h4>Description</h4>
            {subtitleField}
          </div>
          <div className="box">
            <h4>Technical data</h4>

            <table className="textTable">
              <tbody>
                <tr>
                  <td>Created:</td>
                  <td>{formatTime(tutorial.timestamp)}</td>
                </tr>
                <tr>
                  <td>Modified:</td>
                  <td>{formatTime(tutorial.modified)}</td>
                </tr>
                <tr>
                  <td>Number of slides:</td>
                  <td>{tutorial.steps.length}</td>
                </tr>
                <tr>
                  <td>Size of files:</td>
                  <td>{fileSizes || ''}</td>
                </tr>
                <tr>
                  <td>UUID:</td>
                  <td>
                    {tutorial.id.substring(0, 19)}
                    <br />
                    {tutorial.id.substring(19)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="column-half">
          <div className="box">
            <h4>Download</h4>
            <table className="linkTable">
              <tbody>
                <tr>
                  <td>
                    <button className="linkButton" onClick={() => triggerDownload('zipwithcursor')}>
                      Images with cursor
                    </button>
                    {loading['zipwithcursor'] ? (
                      <img src="loading.gif" alt="loading" width="25" className="loading" />
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <button className="linkButton" onClick={() => triggerDownload('zipwithoutcursor')}>
                      Images without cursor
                    </button>
                    {loading['zipwithoutcursor'] ? (
                      <img src="loading.gif" alt="loading" width="25" className="loading" />
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <button className="linkButton" onClick={() => triggerDownload('pptxstandard')}>
                      Powerpoint standard layout
                    </button>
                    {loading['pptxstandard'] ? (
                      <img src="loading.gif" alt="loading" width="25" className="loading" />
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <button className="linkButton" onClick={() => triggerDownload('pptxcustom')}>
                      Powerpoint custom layout
                    </button>
                    {loading['pptxcustom'] ? (
                      <img src="loading.gif" alt="loading" width="25" className="loading" />
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <button className="linkButton" onClick={() => triggerDownload('pdfcustom')}>
                      Pdf
                    </button>
                    {loading['pdfcustom'] ? (
                      <img src="loading.gif" alt="loading" width="25" className="loading" />
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <button className="linkButton" onClick={() => triggerDownload('video')}>
                      Video
                    </button>
                    {loading['video'] ? (
                      <>
                        <img src="loading.gif" alt="loading" width="25" className="loading" /> come back in a few
                        minutes
                      </>
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <a
                      href={`/#/secondscreen/${tutorial.id}/1`}
                      target="_blank"
                      rel="noreferrer"
                      className="linkButton">
                      SecondScreen Agent
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="box">
            <h4>Edit</h4>
            {editBox}
          </div>

          <div className="box">
            <h4>Access Rights</h4>
            <h5>Organization: {tutorial.organization}</h5>
            <input type="radio" name="rights" value="0" defaultChecked="checked" /> no access
            <br />
            <input type="radio" name="rights" value="1" disabled /> view
            <br />
            <input type="radio" name="rights" value="2" disabled /> view & edit
            <br />
          </div>

          <div className="box">
            <h4>Version History</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TutorialView;
