import React, {useState, useEffect, useRef} from 'react';
import {useParams} from 'react-router';
import QRCode from 'qrcode.react';
import axios from 'axios';
import FileDownload from 'js-file-download';
import Switch from '@mui/material/Switch';
import {backend, websocketBackend} from '../config.js';

const Helps = () => {
  const urlParams = useParams();
  const tutorialId = urlParams.tutorialId;
  const sessionId = urlParams.sessionId;
  const [active, setActive] = useState();
  const [loading, setLoading] = useState(false);
  const [algorithm, setAlgorithm] = useState();
  const [algorithmList, setAlgorithmList] = useState([]);
  const ws = useRef();

  useEffect(() => {
    if (sessionId) {
      ws.current = new WebSocket(websocketBackend);

      ws.current.onopen = () => {
        ws.current.send(`{"service": "help", "type": "registration", "sessionid": "${sessionId}"}`);
        //start workshop agent immediately
        ws.current.send(`{"service": "help", "type": "workshop", "eventtype": "start", "sessionid": "${sessionId}"}`);
      };
      ws.current.onmessage = msg => {
        try {
          const message = JSON.parse(msg.data);
          if (message.algorithms) {
            setAlgorithmList(message.algorithms);
            setAlgorithm(message.algorithms[0]);
          }
        } catch (err) {
          console.error(err);
        }
      };
    }
  }, [sessionId]);

  const activateGuide = event => {
    let guideType = event.target.name;
    let activeNew;
    let action;
    if (active === guideType) {
      activeNew = undefined;
      action = 'stop';
    } else {
      activeNew = guideType;
      action = 'start';
    }
    setActive(activeNew);
    setLoading(false);
    ws.current.send(
      `{"type": "${guideType}", "eventtype": "${action}", "algorithm": "${algorithm}", "tutorialid": "${tutorialId}", "sessionid": "${sessionId}", "service": "help"}`,
    );
  };

  const download = () => {
    setLoading(true);
    const downloadUrl = `${backend}/download/pdfcustom?uuid=${tutorialId}`;
    axios
      .get(downloadUrl, {
        responseType: 'blob',
      })
      .then(response => {
        if ('data' in response) {
          if ('content-disposition' in response.headers) {
            const reg = /filename="(.*)"/.exec(response.headers['content-disposition']);
            const fileName = reg[1];
            FileDownload(response.data, fileName);
            setLoading(false);
          } else {
            setTimeout(() => {
              download();
            }, 2000);
          }
        }
      })
      .catch(err => {
        console.error(err.response);
        setLoading(false);
      });
  };

  if (!sessionId || !tutorialId) {
    return <div className="helpOverview">First start a tutorial by opening the Tutorial List.</div>;
  }

  return (
    <div className="helpOverview">
      Following help tools are available
      <div className="helps">
        <div className="card">
          <div className="cardTitle verticallyAligned">PDF</div>
          <div className="cardContent verticallyAligned">
            <button onClick={download} className="linkButton">
              Download
            </button>
            {loading ? <img src="loading.gif" width="20" alt="loading" className="loading" /> : <></>}
          </div>
        </div>
        <div className="card">
          <div className="cardTitle verticallyAligned">Autoclick</div>
          <div className="cardContent verticallyAligned">
            <Switch name="autoclick" onChange={activateGuide} checked={active === 'autoclick'} />
            <select onChange={e => setAlgorithm(e.target.value)}>
              {algorithmList.map(e => (
                <option key={e}>{e}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="card">
          <div className="cardTitle verticallyAligned">Overlay</div>
          <div className="cardContent verticallyAligned">
            <Switch name="overlay" onChange={activateGuide} checked={active === 'overlay'} />
            <select onChange={e => setAlgorithm(e.target.value)}>
              {algorithmList.map(e => (
                <option key={e}>{e}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="card">
          <div className="cardTitle verticallyAligned">Guide on Second Screen</div>
          <div className="cardContent verticallyAligned">
            <QRCode value={`${backend}#/secondscreen/${tutorialId}/${sessionId}`} size={76} />
            <a href={`${backend}#/secondscreen/${tutorialId}/${sessionId}`} target="_blank" rel="noreferrer">
              Link
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Helps;
