import React, {useContext} from 'react';
import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {backend} from '../config.js';
import {AuthContext} from '../context/auth';

const Delete = () => {
  const {token} = useContext(AuthContext);

  const urlParams = useParams();
  let navigate = useNavigate();
  const uuid = urlParams.uuid;

  const executeDelete = () => {
    axios
      .post(
        `${backend}/deleteTutorial`,
        {
          uuid: uuid,
        },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then(() => toOverview())
      .catch(() => {
        toast.error('error deleting tutorial', {toastId: '1234'});
      });
  };

  const toOverview = () => {
    navigate('/tutorialoverview');
  };

  return (
    <div>
      <ToastContainer />
      <button className="downloadButton" onClick={toOverview}>
        back
      </button>
      <div>
        <br />
        <br />
        Are you sure you want to delete the tutorial {uuid} ?<br />
        <button className="downloadButton red" onClick={executeDelete}>
          yes
        </button>
        <button className="downloadButton" onClick={toOverview}>
          no
        </button>
      </div>
    </div>
  );
};

export default Delete;
