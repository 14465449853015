import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter, Route, Routes} from 'react-router-dom';
import Cookies from 'js-cookie';
import Overview from './components/Overview';
import TutorialOverview from './components/TutorialOverview';
import SlideEditor from './components/SlideEditor';
import RoiEditor from './components/RoiEditor';
import NewSlide from './components/NewSlide';
import Delete from './components/Delete';
import SecondScreen from './components/SecondScreen';
import Helps from './components/Helps';
import TutorialView from './components/TutorialView';
import Workshop from './components/Workshop';
import WorkshopSelection from './components/WorkshopSelection';
import Tokenerror from './components/Tokenerror';
import {AuthContext} from './context/auth';

import './style.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    const search = /token=([^&]+)/.exec(window.location.href);
    if (search && search.length === 2) {
      const token = search[1];
      const inEightHours = new Date(new Date().getTime() + 8 * 60 * 60 * 1000);
      //validity of token is checked at each api request on the backend
      Cookies.set('jwt', token, {expires: inEightHours, sameSite: 'Strict'});
    }

    this.state = {
      token: Cookies.get('jwt'),
      editToken: this.editToken,
    };
  }

  editToken = newVal => {
    this.setState({
      token: newVal,
    });
    if (!newVal) {
      Cookies.remove('jwt');
    }
  };

  render() {
    return (
      <AuthContext.Provider value={this.state}>
        <HashRouter>
          <Routes>
            <Route path="/tutorial/:tutorialId" element={<TutorialView />}></Route>
            <Route path="/newslide/:uuid/:prevStepNo/:stepNo/:prevTime/:nextTime" element={<NewSlide />}></Route>
            <Route path="/edittutorial/:uuid/:stepNo" element={<SlideEditor />}></Route>
            <Route path="/edittutorial/:uuid" element={<SlideEditor />}></Route>
            <Route path="/deletetutorial/:uuid" element={<Delete />}></Route>
            <Route path="/editroi/:uuid" element={<RoiEditor />}></Route>
            <Route path="/secondscreen/:tutorialId/:sessionId" element={<SecondScreen />}></Route>
            <Route path="/helps/:tutorialId/:sessionId" element={<Helps />}></Route>
            <Route path="/helps/" element={<Helps />}></Route>
            <Route path="/tutorialoverview" element={<TutorialOverview />}></Route>
            <Route path="/workshop/:workshopId" element={<Workshop />}></Route>
            <Route path="/workshop" element={<WorkshopSelection />}></Route>
            <Route path="/tokenerror" element={<Tokenerror />}></Route>
            <Route path="/" element={<Overview />}></Route>
          </Routes>
        </HashRouter>
      </AuthContext.Provider>
    );
  }
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
