import React, {useState, useEffect, useContext, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import {AuthContext} from '../context/auth';
import Header from './Header.js';
import {backend} from '../config.js';
import {formatTime} from '../util.js';

const TutorialOverview = () => {
  const {token, editToken} = useContext(AuthContext);
  const [tutorialList, setTutorialList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [searchvalue, setSearchvalue] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [showDeleted, setShowDeleted] = useState(false);
  let navigate = useNavigate();

  const handleSearch = useCallback(
    event => {
      let search;
      if (event) {
        search = event.target.value;
      } else {
        search = searchvalue;
      }
      const filteredList = tutorialList.filter(
        item => JSON.stringify(item).toLowerCase().includes(search.toLowerCase()) && (showDeleted || !item.deleted),
      );
      setSearchvalue(search);
      setFilteredList(filteredList);
    },
    [searchvalue, showDeleted, tutorialList],
  );

  useEffect(() => {
    if (!token) {
      return 0;
    }
    axios
      .get(`${backend}/getTutorials?includedeleted=1`, {
        headers: {
          Authorization: token,
        },
      })
      .then(response => {
        if (!response.error) {
          const tutorialList = response.data;
          const filtered = tutorialList.filter(item => item && (showDeleted || !item.deleted));
          setTutorialList(tutorialList);
          setFilteredList(filtered);
          setLoaded(true);
        } else {
          console.log(response);
        }
      })
      .catch(err => {
        console.log(err);
        editToken(undefined);
        setLoaded(true);
      });
  }, [token, editToken, showDeleted]);

  useEffect(() => {
    handleSearch();
  }, [showDeleted, handleSearch]);

  const columns = [
    {
      name: 'Title',
      selector: row => row.title,
      sortable: true,
    },
    {
      name: 'User',
      selector: row => row.username,
      sortable: true,
    },
    {
      name: 'Organization',
      selector: row => row.organization,
      sortable: true,
    },
    {
      name: 'Creation date',
      selector: row => row.timestamp,
      sortable: true,
      format: row => formatTime(row.timestamp),
    },
    {
      cell: row => <a href={`/#/tutorial/${row.id}`}>{row.editRights < 2 ? <>Details</> : <>Details/Edit</>}</a>,
    },
    {
      name: 'deleted',
      selector: row => row.deleted,
      omit: true,
    },
  ];

  const conditionalRowStyles = [
    {
      when: row => row.deleted,
      style: {
        color: '#AAA',
      },
    },
  ];

  if (!token) {
    return (
      <div className="warning">
        Authentication unsuccessful. Please log in on{' '}
        <a href="http://agent.mylivezone.com">http://agent.mylivezone.com</a>, start a workplace and click on "Downloads
        JWT".
      </div>
    );
  }
  return (
    <div>
      <Header />
      <button className="downloadButton" onClick={() => navigate('/')}>
        back
      </button>
      <div className="searchdiv">
        <input
          type="text"
          className="searchbox"
          placeholder="Search..."
          value={searchvalue}
          onChange={handleSearch}></input>
      </div>
      <div className="overview-table">
        {
          <DataTable
            columns={columns}
            data={filteredList ? filteredList : []}
            keyField={'id'}
            highlightOnHover
            noHeader
            dense
            defaultSortFieldId={4}
            defaultSortAsc={false}
            progressPending={!loaded}
            progressComponent={'loading...'}
            noDataComponent={'no data to display'}
            conditionalRowStyles={conditionalRowStyles}
          />
        }
        <button className="linkButton" onClick={() => setShowDeleted(!showDeleted)}>
          {showDeleted ? 'hide deleted tutorials' : 'show deleted tutorials'}
        </button>
      </div>
    </div>
  );
};

export default TutorialOverview;
