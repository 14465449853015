import React, {useState, useEffect, useContext, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {useParams} from 'react-router';
import axios from 'axios';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {backend} from '../config.js';
import {blank} from '../pngImages.js';
import {AuthContext} from '../context/auth';
import Header from './Header.js';

/*
**
ROI editor is not in use
**
*/

const RoiEditor = () => {
  const {token} = useContext(AuthContext);

  const [stepNo, setStepNo] = useState(0);
  const [imageStream, setImageStream] = useState([]);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const canvasRef = useRef();
  let navigate = useNavigate();
  const urlParams = useParams();
  const uuid = urlParams.uuid;

  useEffect(() => {
    const prom1 = axios.get(`${backend}/getTutorials?uuid=${uuid}&props=images|cursor`, {
      headers: {
        Authorization: token,
      },
    });
    const prom2 = axios.get(`${backend}/getTutorials?uuid=${uuid}&props=roi_ml`, {
      headers: {
        Authorization: token,
      },
    });
    const promises = [prom1, prom2];
    Promise.all(promises)
      .then(data => {
        let image_response = data[0];
        let roi_response = data[1];
        if (!('data' in image_response) || image_response.data[0].steps.length === 0) {
          toast.error('no images found', {position: 'top-right'});
        } else {
          let imageStreamNew = image_response.data[0].steps;
          for (let idx in imageStreamNew) {
            if (roi_response.data[0].steps[idx].image) {
              imageStreamNew[idx].roi = getPngDimensions(roi_response.data[0].steps[idx].image);
              imageStreamNew[idx].stepNo = idx;
            }
          }
          imageStreamNew = imageStreamNew.filter(step => step.roi);
          setImageStream(imageStreamNew);
        }
      })
      .catch(err => {
        console.error(err);
        setImageStream([]);
        toast.error('no images found', {position: 'top-right'});
      });
  }, []);

  useEffect(() => {
    initSaving();
  }, [imageStream]);

  useEffect(() => {
    drawRects();
  }, [imageStream, stepNo]);

  const getPngDimensions = base64 => {
    const header = atob(base64.slice(0, 50)).slice(16, 24);
    const uint8 = Uint8Array.from(header, c => c.charCodeAt(0));
    const dataView = new DataView(uint8.buffer);

    return {
      width: dataView.getInt32(0),
      height: dataView.getInt32(4),
    };
  };

  const initSaving = () => {
    //only save to DB if there was no typing for 2s
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => {
        saveToDB();
      }, 2000),
    );
  };

  const saveToDB = () => {
    if (imageStream.length > 0) {
      if (imageStream[stepNo]) {
        const step = imageStream[stepNo];
        if (step.modified) {
          axios
            .post(
              `${backend}/editROI`,
              {
                uuid: uuid,
                step: step.stepNo,
                width: step.roi.width,
                height: step.roi.height,
              },
              {
                headers: {
                  Authorization: token,
                },
              },
            )
            .then(response => {
              if (response.data && response.data.width) {
                const newWidth = response.data.width;
                const newHeight = response.data.height;
                let newImageStream = [...imageStream];
                newImageStream[stepNo].modified = false;
                if (
                  response.data.stepNo === imageStream[stepNo].stepNo &&
                  (newWidth !== step.roi.width || newHeight !== step.roi.height)
                ) {
                  newImageStream[stepNo].roi.width = newWidth;
                  newImageStream[stepNo].roi.height = newHeight;
                }
                setImageStream(newImageStream);
              }
            })
            .catch(err => {
              console.error(err);
              toast.error('error saving text', {toastId: '1234'});
            });
        }
      }
    }
  };

  const setSize = event => {
    const size = parseInt(event.target.value);
    const name = event.target.name;
    let newImageStream = [...imageStream];
    newImageStream[stepNo].roi[name] = size;
    newImageStream[stepNo].modified = true;
    setImageStream(newImageStream);
  };

  const toOverview = () => {
    saveToDB();
    navigate(`/tutorial/${uuid}`);
  };

  const goBack = () => {
    saveToDB();
    setStepNo(stepNo - 1);
  };

  const goForth = () => {
    saveToDB();
    setStepNo(stepNo + 1);
  };

  const drawRects = () => {
    const step = imageStream[stepNo];
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');

      const roiWidth = parseInt((canvas.width / step.imagesize.width) * step.roi.width);
      const roiHeight = parseInt((canvas.height / step.imagesize.height) * step.roi.height);
      const xOriginal = parseInt(step.event.mouseposition.xPos - step.roi.width / 2);
      const yOriginal = parseInt(step.event.mouseposition.yPos - step.roi.height / 2);

      let x0 = parseInt((canvas.width / step.imagesize.width) * xOriginal);
      let y0 = parseInt((canvas.height / step.imagesize.height) * yOriginal);

      if (x0 < 0) {
        x0 = 0;
      } else if (x0 + roiWidth > canvas.width) {
        x0 = canvas.width - roiWidth;
      }
      if (y0 < 0) {
        y0 = 0;
      } else if (y0 + roiHeight > canvas.height) {
        y0 = canvas.height - roiHeight;
      }

      let img = new Image();
      img.src = canvas.getAttribute('src');
      img.onload = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.imageSmoothingEnabled = false;
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        ctx.beginPath();
        ctx.strokeStyle = 'red';
        ctx.rect(x0, y0, roiWidth, roiHeight);
        ctx.stroke();
      };
    }
  };

  let editorView;
  let leftArrow;
  let rightArrow;
  if (imageStream.length > 0) {
    let step = imageStream[stepNo];
    let imageData;
    if (step.image) {
      imageData = `data:image/png;base64,${step.image}`;
    } else {
      imageData = blank;
    }
    const imgWidth = 600;
    const imgHeight = parseInt((600 / step.imagesize.width) * step.imagesize.height);

    editorView = (
      <>
        <div className={step.instructions.display ? 'editorImage' : 'editorImage disabled'}>
          <canvas ref={canvasRef} width={imgWidth} height={imgHeight} src={imageData}></canvas>
        </div>
        Width{' '}
        <input
          type="number"
          name="width"
          value={step.roi.width}
          onChange={setSize}
          className="editorInputNumber"></input>
        &#160;&#160; Height{' '}
        <input
          type="number"
          name="height"
          value={step.roi.height}
          onChange={setSize}
          className="editorInputNumber"></input>
      </>
    );

    leftArrow = (
      <button className="arrow" onClick={goBack}>
        &#8249;
      </button>
    );
    rightArrow = (
      <button className="arrow" onClick={goForth}>
        &#8250;
      </button>
    );
    if (stepNo === 0) {
      leftArrow = <button className="arrow arrowInactive">&#8249;</button>;
    } else if (stepNo === imageStream.length - 1) {
      rightArrow = <button className="arrow arrowInactive">&#8250;</button>;
    }
  } else {
    editorView = '';
    leftArrow = '';
    rightArrow = '';
  }

  let slideNumberView = '';
  if (imageStream.length > 0) {
    slideNumberView = `Slide ${stepNo + 1}/${imageStream.length}`;
  }

  return (
    <>
      <ToastContainer />
      <Header />
      <button className="downloadButton" onClick={toOverview}>
        back
      </button>
      <span className="titlelike">ROI Editor: {slideNumberView}</span>
      <div className="editor">
        {leftArrow}
        <div className="editorView">{editorView}</div>
        {rightArrow}
      </div>
    </>
  );
};

export default RoiEditor;
