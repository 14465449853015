import React, {useState, useEffect, useContext} from 'react';
import {useParams} from 'react-router';
import {Navigate} from 'react-router-dom';
import axios from 'axios';
import {Scrollbars} from 'react-custom-scrollbars-2';
import {backend} from '../config.js';
import {AuthContext} from '../context/auth';

const Workshop = () => {
  const {token} = useContext(AuthContext);
  const [loaded, setLoaded] = useState(false);
  const [workshopdata, setWorkshopdata] = useState([]);
  const [large, setLarge] = useState();
  const [currentTime, setCurrentTime] = useState(0);
  const [galleryMaxHeight, setGalleryMaxHeight] = useState(window.innerHeight - 60);
  const {workshopId} = useParams();

  useEffect(() => {
    console.log('reloading');
    if (token && workshopId) {
      axios
        .get(`${backend}/getWorkshopStream?workshopId=${workshopId}`, {
          headers: {
            Authorization: token,
          },
        })
        .then(response => {
          if (response.data) {
            console.log(response.data);
            setWorkshopdata(response.data);
          }
          setLoaded(true);
        })
        .catch(err => {
          console.log(err);
          setLoaded(true);
        });
    }
  }, [token, workshopId, currentTime]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setGalleryMaxHeight(window.innerHeight - 60);
    });

    const interval = setInterval(() => setCurrentTime(Date.now()), 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const formatTime = seconds => {
    if (seconds < 3600) {
      return `${parseInt(seconds / 60)} min`;
    }
    return `${parseInt(seconds / 3600)} h`;
  };

  const createContent = () => {
    if (!loaded) {
      return <div className="center">loading...</div>;
    } else {
      let nothingToDisplay = true;
      let gallery = [];
      for (let idx in workshopdata) {
        if (workshopdata[idx].age > 86400) {
          continue;
        }
        let classnames = 'galleryelement';
        if (large === idx) {
          classnames = 'galleryelement focused';
        }
        nothingToDisplay = false;
        gallery.push(
          <div key={idx} className={classnames}>
            <img src={`data:image/png;base64,${workshopdata[idx].screenshot}`} alt="" onClick={() => setLarge(idx)} />
            <div className="userinfo">
              {workshopdata[idx].username}, {formatTime(workshopdata[idx].age)} ago
            </div>
          </div>,
        );
      }
      if (nothingToDisplay) {
        return <div className="center">no user is online</div>;
      }
      return (
        <>
          <div className="workshopgallery">
            <Scrollbars autoHeight autoHeightMax={galleryMaxHeight}>
              {gallery}
            </Scrollbars>
          </div>
          {large ? (
            <div className="largescreen">
              <img src={`data:image/png;base64,${workshopdata[large].screenshot}`} alt="" />
            </div>
          ) : (
            <></>
          )}
        </>
      );
    }
  };

  return (
    <div className="workshop">
      {!token ? <Navigate to="/tokenerror" /> : <></>}
      <div className="title">
        <h1>Workshop</h1>
      </div>
      {createContent()}
    </div>
  );
};

export default Workshop;
